var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"name":"buyersOverview","items":_vm.buyers,"headers":_vm.headers,"loading":_vm.isLoading,"items-length":_vm.totalItems,"has-add-button":!_vm.disabledActionForNonAdmin,"add-button-text":_vm.$tc('addProspectiveBuyer', 2),"clickable":true,"initial-options":_vm.initialTableOptions,"highlightRow":_vm.highlightRow,"itemsPerPageOptions":[10, 50, 200, 400]},on:{"optionsChanged":_vm.onOptionsChange,"addItemClicked":_vm.addItemClicked,"rowClicked":_vm.onRowClick,"selectAll":_vm.handleSelectAll},scopedSlots:_vm._u([{key:"export",fn:function(){return [_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.openExportDialog}},[_vm._v("Export ")])]},proxy:true},(_vm.selectedBuyersLocal.length > 0)?{key:"body.prepend",fn:function(ref){
var isMobile = ref.isMobile;
return [_c('BuyersSelectedActions',{attrs:{"project":_vm.project,"selected":_vm.selectedBuyersLocal,"col-num":_vm.headers.length,"is-mobile":isMobile},on:{"openActionDialog":function($event){return _vm.openActionDialog($event)},"resetSelection":function($event){_vm.selectedBuyersLocal = []},"deleteAll":_vm.deleteAllSelected}})]}}:null,{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"row-select-checkbox",attrs:{"input-value":_vm.isRowSelected(item.id)},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.rowSelection(item)}}})]}},{key:"item.buyerType.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get(item, "contact.buyerType.name"))+" ")]}},{key:"item.isPriority",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.isPriority === true),expression:"item.isPriority === true"}],attrs:{"color":"yellow darken-1","dark":""},domProps:{"textContent":_vm._s(_vm.$tc('priority', 1))}})]}},{key:"item.contact.mail",fn:function(ref){
var item = ref.item;
return [(item.contact.email)?_c('Link',{attrs:{"href":item.contact.email,"prefix":_vm.LinkType.MAIL}}):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.contact.company)?_c('router-link',{attrs:{"to":_vm.companyDetailLink(item.contact.company)}},[_vm._v(" "+_vm._s(item.contact.company.name)+" ")]):_vm._e()]}},{key:"item.contact.phoneMobile",fn:function(ref){
var item = ref.item;
return [(item.contact.phoneMobile)?_c('Link',{attrs:{"href":item.contact.phoneMobile,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}},{key:"item.contact.lastName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.contactDetailLink(item.contact)}},[_vm._v(" "+_vm._s(item.contact.firstName)+" ")])]}},{key:"item.contact.firstName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.contactDetailLink(item.contact)}},[_vm._v(" "+_vm._s(item.contact.lastName)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(item.status))+" ")]}},{key:"item.statusUpdatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatUnixTimestamp")(item.statusUpdatedAt))+" ")]}},{key:"item.ndaSigned",fn:function(ref){
var item = ref.item;
return [_c('NdaSignedCell',{attrs:{"contact":item.contact},on:{"nda-approved-changed":_vm.onOptionsChange,"nda-uploaded":_vm.onOptionsChange}})]}},{key:"item.ndaSent",fn:function(ref){
var item = ref.item;
return [_c('NdaSentCell',{attrs:{"contact":item.contact},on:{"nda-sent":_vm.onOptionsChange}})]}},{key:"item.sentExpose",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanOrNaTexValue")(item.sentExpose))+" ")])]}},{key:"item.filledExpressionOfInterest",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanOrNaTexValue")(item.filledExpressionOfInterest))+" ")]),(_vm.hasFilledExpressionOfInterest(item))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEoiDialog(item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pen')}})],1):_vm._e()]}},{key:"item.interested",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanOrNaTexValue")(item.interested))+" ")])]}},{key:"item.purchaseOffer",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanOrNaTexValue")(item.purchaseOffer))+" ")])]}},{key:"item.meetingArranged",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanOrNaTexValue")(item.meetingArranged))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionButtonWithTooltip',{attrs:{"icon":"mdi-delete","tooltip":_vm.$tc('delete', 1)},on:{"click":function($event){return _vm.deleteBuyer(item.id)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }