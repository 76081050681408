



































































































































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import TranslatedEnumListsMixin from "../../mixins/TranslatedEnumListsMixin";
import DataTable from "@/components/data-table/DataTable.vue";
import HasAttachmentCell from "@/components/HasAttachmentCell.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import { ITableHeader } from "@/components/data-table/types";
import ProjectBuyer, {
  hasFilledExpressionOfInterest,
} from "@/entity/ProjectBuyer";
import NdaSignedCell from "@/components/contact/NdaSignedCell.vue";
import NdaSentCell from "@/components/contact/NdaSentCell.vue";
import BuyersSelectedActions from "@/components/project-buyers/BuyersSelectedActions.vue";
import { LinkType } from "@/enums";
import Project from "@/entity/Project";
import contactDetailNavigationTo from "@/utils/contactDetailNavigateTo";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";
import { NotificationType } from "@/entity/Notification";
import Contact, { getContactFullName } from "@/entity/Contact";
import { ReferenceItem } from "@/services/types";
import Notification from "@/entity/Notification";
import { get, uniqBy } from "lodash";
import Link from "@/components/common/Link.vue";

@Component({
  components: {
    DataTable,
    HasAttachmentCell,
    ActionButtonWithTooltip,
    NdaSignedCell,
    NdaSentCell,
    BuyersSelectedActions,
    Link,
  },
})
export default class ProjectBuyersDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @PropSync("selectedBuyers", { type: Array, required: true })
  selectedBuyersLocal!: ProjectBuyer[];

  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Array }) contacts!: Contact[];
  @Prop({ required: true, type: Array }) buyerTypes!: ReferenceItem[];
  @Prop({ required: true, type: Array }) notifications!: Notification[];
  @Prop({ required: true, type: Boolean }) disabledActionForNonAdmin!: boolean;
  @Prop({ type: Array, required: true }) buyers!: ProjectBuyer[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) addItemClicked!: () => void;
  @Prop({ type: Function, required: true }) openExportDialog!: () => void;
  @Prop({ type: Function, required: true }) onRowClick!: (
    buyer: ProjectBuyer
  ) => void;
  @Prop({ type: Function, required: true }) openActionDialog!: (
    type: string
  ) => void;
  @Prop({ type: Function, required: true }) openEoiDialog!: (
    buyer: ProjectBuyer
  ) => void;
  @Prop({ type: Function, required: true }) deleteBuyer!: (
    historyId: number
  ) => void;
  @Prop({ type: Function, required: true })
  deleteAllSelected!: () => Promise<void>;

  initialTableOptions: any | null = null;
  hasFilledExpressionOfInterest = hasFilledExpressionOfInterest;
  contactDetailLink = contactDetailNavigationTo;
  companyDetailLink = companyDetailNavigationTo;
  contactFullName = getContactFullName;
  get = get;
  LinkType = LinkType;

  get headers(): ITableHeader[] {
    return [
      {
        text: this.$tc("select", 1),
        value: "select",
        width: "60px",
        filterType: "selectAll",
      },
      {
        text: this.$tc("priority", 1),
        value: "isPriority",
        align: "center",
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "120px",
      },
      {
        text: this.$tc("name", 1),
        value: "contact.lastName",
        filterType: "text",
        width: "200px",
      },
      {
        text: this.$tc("firstName", 1),
        value: "contact.firstName",
        filterType: "text",
        width: "200px",
      },
      {
        text: this.$tc("email", 1),
        value: "contact.mail",
        filterType: "text",
        width: "200px",
      },
      {
        text: this.$tc("companyName", 1),
        value: "company",
        filterType: "text",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("phoneMobile", 1),
        value: "contact.phoneMobile",
        width: "150px",
      },
      {
        text: this.$tc("status", 1),
        value: "status",
        options: this.translatedEnums.buyerStatuses,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("buyerType", 1),
        value: "buyerType.name",
        filterValue: "buyerTypeId",
        sortable: true,
        options: this.buyerTypes,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("lastStatusChange", 1),
        value: "statusUpdatedAt",
        filterType: "date",
        width: "150px",
      },
      {
        text: this.$tc("ndaSigned"),
        value: "ndaSigned",
        filterValue: "contact.ndaSigned",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("ndaSent"),
        value: "ndaSent",
        filterValue: "contact.ndaSent",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("exposeSent"),
        value: "sentExpose",
        filterValue: "sentExpose",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("expressionOfInterest"),
        value: "filledExpressionOfInterest",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("interested"),
        value: "interested",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("offer"),
        value: "purchaseOffer",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("meetingArranged"),
        value: "meetingArranged",
        sortable: true,
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        width: "150px",
      },
      {
        text: this.$tc("actions"),
        value: "action",
        width: "80px",
      },
    ];
  }

  get mentionedBuyers(): number[] {
    const buyerIds: Set<number> = new Set();

    this.notifications.forEach((notification) => {
      if (notification.project !== null) {
        const notificationProjectId = notification.project.id;

        if (
          (this.project.id as number) === notificationProjectId &&
          (notification.type === NotificationType.EXPOSE_ORDERED ||
            notification.type === NotificationType.TEASER_ACCEPTED)
        ) {
          const buyerId = notification.buyerId;
          if (buyerId !== null) {
            buyerIds.add(buyerId);
          }
        }
      }
    });

    return Array.from(buyerIds);
  }

  highlightRow(item: ProjectBuyer): boolean {
    return (
      this.mentionedBuyers.includes(item.id as number) &&
      item.sentExpose !== true
    );
  }

  isRowSelected(id: number): boolean {
    return this.selectedBuyersLocal.findIndex((buyer) => buyer.id === id) > -1;
  }

  rowSelection(item: ProjectBuyer): void {
    const existingIndex = this.selectedBuyersLocal.findIndex(
      (buyer) => buyer.id === item.id
    );

    if (existingIndex > -1) {
      this.selectedBuyersLocal.splice(existingIndex, 1);
    } else {
      this.selectedBuyersLocal.push(item);
    }
  }

  handleSelectAll(): void {
    this.selectedBuyersLocal = uniqBy(
      this.buyers.concat(this.selectedBuyersLocal),
      (buyer) => {
        return buyer.id;
      }
    );
  }

  resetNavigationForNewestItems(): void {
    this.initialTableOptions = {
      page: 1,
      itemsPerPage: 10,
      filterBy: {},
      sortBy: ["statusUpdatedAt"],
      sortDesc: [true],
    };
  }

  created() {
    if (this.$route.query["source"] === "notification") {
      this.resetNavigationForNewestItems();
    }
  }
}
