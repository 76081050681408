




























































import { Vue, Component, Watch } from "vue-property-decorator";
import SectionHeader from "@/components/project-buyers-form/SectionHeader.vue";
import Contact from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    SectionHeader,
  },
})
export default class ManualAdd extends Vue {
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  isSectionActive = true;
  contacts: Contact[] = [];
  searchedContact: Contact | null = null;
  selectedContacts: Set<Contact> = new Set();

  selectContact(): void {
    if (this.searchedContact) {
      this.selectedContacts = new Set(
        this.selectedContacts.add(this.searchedContact)
      ); // Hack to trigger Vue's reactivity
      this.searchedContact = null;
    }
  }

  deleteContact(contact: Contact): void {
    this.selectedContacts.delete(contact);
    this.selectedContacts = new Set(this.selectedContacts); // Hack to trigger Vue's reactivity
  }

  get selectedAsArray(): Contact[] {
    return [...this.selectedContacts];
  }

  async fetchContacts(): Promise<void> {
    const response = await ContactsService.findWithCompanyAndBuyer();
    // const response = await ContactsService.find({
    //   itemsPerPage: ALL_ITEMS_PER_PAGE,
    //   filterBy: {
    //     notInBuyersListForProject: this.$route.params.projectId,
    //     isExternal: false,
    //     isBlocked: false,
    //     isDisabled: false,
    //   },
    // });
    this.contacts = response.content;
  }

  get selectedIds(): number[] {
    return this.selectedAsArray.map((contact) => contact.id as number);
  }

  @Watch("selectedAsArray")
  onSelectedChange(): void {
    this.$emit("change", this.selectedIds);
  }

  companyName(contact: any) {
    return contact.company.name;
  }

  buyerType(contact: any) {
    if (contact)
      return this.buyerTypes.find((buyer) => {
        if (buyer.id === contact.buyerTypeId) return buyer;
      })?.name;
  }

  @Watch("isSectionActive", { immediate: true })
  async onIsSectionActiveChange(isSectionActive: boolean): Promise<void> {
    if (!isSectionActive) {
      this.$emit("change", []);
    } else {
      await this.fetchContacts();
      this.$emit("change", this.selectedIds);
    }
  }
}
